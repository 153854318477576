const _ = require('lodash')

const requirePromise = (requireFn, packageName) => new Promise(resolve => {
    requireFn(packageName, resolve)
})

const fetchFallbackJson = (fetchFn, fallbackUrls) => new Promise((resolve, reject) => {
    let fallbackCount = 0

    const tryToFetch = error => {
        if (fallbackCount >= fallbackUrls.length) {
            reject(error)
            return
        }

        const fallbackUrl = fallbackUrls[fallbackCount++]
        fetchFn(fallbackUrl, null, 'json', resolve, tryToFetch)
    }

    tryToFetch()
})

const fetchPageJson = ({
    fetchFn,
    requireFn,
    getDataFixerParams,
    fixedPageUrl,
    fixedViewModePageUrl,
    shouldFetchJsonByViewMode,
    onSuccess,
    onError,
    fallbackUrls = [],
    reportFixedDataFetchStarted,
    reportFixedDataFetchEnded,
    reportFixedDataFetchError
}) => {
    const fetchFromFallback = async () => {
        try {
            const [pageJson, dataFixer] = await Promise.all([
                fetchFallbackJson(fetchFn, fallbackUrls),
                requirePromise(requireFn, 'santa-data-fixer')
            ])

            onSuccess(dataFixer.fix({
                ...getDataFixerParams(),
                pageId: _.get(pageJson, ['structure', 'id'], 'masterPage'),
                pageJson
            }))
        } catch (err) {
            if (onError) {
                onError(err)
                return
            }
            throw err
        }
    }

    const onFixedDataFetchSuccess = (...args) => {
        reportFixedDataFetchEnded()
        onSuccess(...args)
    }

    const onFixedDataFetchError = ({status = 0, statusText = ''} = {}) => {
        reportFixedDataFetchError({status, statusText, failRequestUrl: fixedPageUrl})
        fetchFromFallback()
    }

    const fetchFixedPage = () => {
        fetchFn(fixedPageUrl, null, 'json', onFixedDataFetchSuccess, onFixedDataFetchError)
    }

    const onFixedDataByViewModeFetchError = ({status = 0, statusText = ''} = {}) => {
        reportFixedDataFetchError({status, statusText, failRequestUrl: fixedViewModePageUrl})
        fetchFixedPage()
    }

    const fetchFixedPageByViewMode = () => {
        fetchFn(fixedViewModePageUrl, null, 'json', onFixedDataFetchSuccess, onFixedDataByViewModeFetchError)
    }

    reportFixedDataFetchStarted()
    const fetchPage = shouldFetchJsonByViewMode ? fetchFixedPageByViewMode : fetchFixedPage
    fetchPage()
}

module.exports = {
    loadPage(setPageToLoad, pageId, requestInfo) {
        setPageToLoad(pageId, requestInfo)
    },
    fetchPageJson,
    reportFixedDataFetchError: (capturePageRequestError, tags, info) => {
        capturePageRequestError({
            tags,
            extras: {
                info
            }
        })
    },
    pageRequestSuccess(setPageData, successCallback, data) {
        setPageData(data)
        if (successCallback) {
            successCallback(data)
        }
    },
    fetchPageForPreview: (fetchFn, pageId, viewMode, onSuccess) => {
        const page = fetchFn(pageId, viewMode)
        onSuccess(page)
    }
}
